import React from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '@blueprintjs/datetime';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@blueprintjs/core';

const DateFilter = (props) => {
  const { module, subModule, controlName } = props;
  const control = useSelector((store) => store.controlsReducer?.[module]?.[subModule]?.[controlName]);
  const selectedDate = control?.selection?.selectedDate && new Date(control?.selection?.selectedDate);

  // const minDate = control?.values[0] ? new Date(control.values[0]) : null;
  // const maxDate = control?.values[0] ? new Date(control.values.slice(-1)[0]) : null;
  // console.log({ minDate, maxDate });

  const dispatch = useDispatch();
  const handleUpdateFilter = (date) => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: {
        selectedDate: date && format(new Date(date), 'yyyy-MM-dd'),
      },
    });
  };

  const deleteSelection = () => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: { selectedDate: null },
    });
  };

  return (
    <div className="DateRangeFilter" style={{ width: `${props.width}px`, display: 'flex' }}>
      <DateInput
        formatDate={(date) => format(date, 'MM/dd/yy')}
        onChange={handleUpdateFilter}
        value={selectedDate}
        parseDate={(str) => new Date(str)}
        placeholder="MM/DD/YY"
        inputProps={{ fill: true }}
        closeOnSelection={props.closeOnSelection}
        // minDate={minDate}
        // maxDate={maxDate}
      />
      {props.showDeleteSelection && (
        <Button minimal small onClick={deleteSelection} icon="cross" style={{ margin: '3px 1px 0 -27px', height: '25px', zIndex: 2 }} />
      )}
    </div>
  );
};

DateFilter.propTypes = {
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  width: PropTypes.number,
  showDeleteSelection: PropTypes.bool,
  closeOnSelection: PropTypes.bool,
};

DateFilter.defaultProps = {
  width: 170,
  showDeleteSelection: false,
  closeOnSelection: true,
};

export default DateFilter;
