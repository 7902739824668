import React from 'react';
import DataLoader from 'Components/DataLoader/DataLoader';
import Filter from 'Components/Filter/Filter';
import OrbitalDataChart from 'Components/OrbitalDataChart/OrbitalDataChart';
import OrbitalSwitch from 'Components/OrbitalSwitch/OrbitalSwitch';
import { useSelector } from 'react-redux';
import { format, sub } from 'date-fns';
import DateRangeFilter from 'Components/DateRangeFilter/DateRangeFilter';
import DateFilter from 'Components/DateFilter/DateFilter';
import datasetParams from '../utils/datasetParams';

const DCOBookings = () => {
  const controls = useSelector((store) => store.controlsReducer?.Callisto?.DCO_Bookings);
  // console.log({ controls });

  return (
    <DataLoader module="Callisto" subModule="DCO_Bookings" pageTitle="DCO Bookings" datasetParams={datasetParams}>
      <section className="topRow">
        <article className="controls">
          <section>
            <OrbitalSwitch module="Callisto" subModule="DCO_Bookings" controlName="toggleCumulative" />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="BookYear" placeholder="Book Year..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="BookYearMonth" placeholder="Book Year-Month..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="EventYear" placeholder="Event Year..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="EventYearMonth" placeholder="Event Year-Month..." multiple width={200} />
            <DateRangeFilter
              module="Callisto"
              subModule="DCO_Bookings"
              controlName="EventDate.Range"
              placeholder="Event Date..."
              showDeleteSelection
              width={200}
            />
            <DateFilter
              module="Callisto"
              subModule="DCO_Bookings"
              controlName="EventDate"
              placeholder="Event Date..."
              showDeleteSelection
              width={200}
              closeOnSelection={false}
            />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="Channel" placeholder="Channel..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="ItemType" placeholder="Item Type..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="SOR" placeholder="SOR..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="StateGroup" placeholder="State Group..." multiple width={200} />
            <Filter module="Callisto" subModule="DCO_Bookings" controlName="Reseller" placeholder="Reseller..." multiple width={200} />
          </section>
        </article>
      </section>
      <main style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <OrbitalDataChart
          title="Bookings"
          module="Callisto"
          subModule="DCO_Bookings"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: 'Day',
            },
            yAxis: [
              { fieldName: 'BookingsUnits', modifier: 'CY', mode: 'line', markers: false, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'BookingsUnits', modifier: 'PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'BookingsUnits', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: false,
            height: 400,
            widthRatio: 3,
            rangeSlider: [format(sub(new Date(), { days: 180 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
          }}
        />
        <OrbitalDataChart
          title="Bookings Per Cap"
          module="Callisto"
          subModule="DCO_Bookings"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: 'Day',
            },
            yAxis: [
              { fieldName: 'BookingsPerCap', modifier: 'CY', mode: 'line', markers: false, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'BookingsPerCap', modifier: 'PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'BookingsPerCap', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: false,
            height: 400,
            widthRatio: 3,
            rangeSlider: [format(sub(new Date(), { days: 180 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
          }}
        />
        <OrbitalDataChart
          title="Bookings Revenues"
          module="Callisto"
          subModule="DCO_Bookings"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: 'Day',
            },
            yAxis: [
              { fieldName: 'BookingsRevenues', modifier: 'CY', mode: 'line', markers: false, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'BookingsRevenues', modifier: 'PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'BookingsRevenues', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: false,
            height: 400,
            widthRatio: 3,
            rangeSlider: [format(sub(new Date(), { days: 180 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
          }}
        />
      </main>
    </DataLoader>
  );
};

export default DCOBookings;
