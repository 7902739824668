import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { sideBarItems } from 'utils/appDefinitions';
import './Callisto.css';
import DCOTable from './DCOTable/DCOTable';
import DCOCharts from './DCOCharts/DCOCharts';
import DCOBookings from './DCOBookings/DCOBookings';
import DCOWeeklyReports from './DCOWeeklyReports/DCOWeeklyReports';

const CallistoContainer = (props) => {
  // Set page title
  React.useEffect(() => {
    document.title = `Orbital${process.env.REACT_APP_STAGE === 'production' ? '' : '-dev'} - ${
      sideBarItems.find((item) => item.name === 'Callisto').displayName
    }`;
  }, []);

  return (
    <div className="Callisto">
      <Switch>
        <Route exact path="/Callisto/DCO Weekly Reports" component={DCOWeeklyReports} />
        <Route exact path="/Callisto/DCO Bookings" component={DCOBookings} />
        <Route exact path="/Callisto/DCO Table" component={DCOTable} />
        <Route exact path="/Callisto/DCO Charts" component={DCOCharts} />
      </Switch>
    </div>
  );
};

export default CallistoContainer;
