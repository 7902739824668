import React from 'react';
import DataLoader from 'Components/DataLoader/DataLoader';
import Filter from 'Components/Filter/Filter';
import OrbitalDataChartContainer from 'Components/OrbitalDataChart/OrbitalDataChart';
import OrbitalSwitch from 'Components/OrbitalSwitch/OrbitalSwitch';
import { useSelector } from 'react-redux';
import datasetParams from '../utils/datasetParams';

const DCOCharts = () => {
  const controls = useSelector((store) => store.controlsReducer?.Callisto?.DCO_Charts);
  // console.log({ controls });

  return (
    <DataLoader module="Callisto" subModule="DCO_Charts" pageTitle="DCO Charts" datasetParams={datasetParams}>
      <section className="topRow">
        <article className="controls">
          <section>
            <Filter
              module="Callisto"
              subModule="DCO_Charts"
              controlName="TimeGrouping"
              placeholder="Book Year..."
              values={['Day', 'Week', 'Month', 'Quarter', 'Year']}
              width={100}
            />
            <OrbitalSwitch module="Callisto" subModule="DCO_Charts" controlName="toggleCumulative" />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCO_Charts" controlName="BookYear" placeholder="Book Year..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="BookYearMonth" placeholder="Book Year-Month..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="EventYear" placeholder="Event Year..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="EventYearMonth" placeholder="Event Year-Month..." multiple />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCO_Charts" controlName="Channel" placeholder="Channel..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="ItemType" placeholder="Item Type..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="SOR" placeholder="SOR..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="StateGroup" placeholder="State Group..." multiple />
            <Filter module="Callisto" subModule="DCO_Charts" controlName="Reseller" placeholder="Reseller..." multiple />
          </section>
        </article>
      </section>
      <main style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <OrbitalDataChartContainer
          title="Sales"
          module="Callisto"
          subModule="DCO_Charts"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: controls?.TimeGrouping?.selection[0],
            },
            yAxis: [
              { fieldName: 'SalesUnits', modifier: 'CY', markers: true, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'SalesUnits', modifier: 'PY', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'SalesUnits', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: true,
            format: ',',
            height: 400,
            widthRatio: 2,
          }}
        />
        <OrbitalDataChartContainer
          title="Sales Revenues"
          module="Callisto"
          subModule="DCO_Charts"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: controls?.TimeGrouping?.selection[0],
            },
            yAxis: [
              { fieldName: 'SalesRevenues', modifier: 'CY', markers: true, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'SalesRevenues', modifier: 'PY', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'SalesRevenues', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: true,
            format: '$~s',
            height: 400,
            widthRatio: 2,
          }}
        />
        <OrbitalDataChartContainer
          title="Sales Per Cap"
          module="Callisto"
          subModule="DCO_Charts"
          datasetParams={datasetParams}
          chartDefinition={{
            xAxis: {
              fieldName: 'BookDate',
              modifier: controls?.TimeGrouping?.selection[0],
            },
            yAxis: [
              { fieldName: 'SalesPerCap', modifier: 'CY', markers: true, lineWidth: 2, secondaryAxis: false },
              { fieldName: 'SalesPerCap', modifier: 'PY', markers: false, lineWidth: 1, secondaryAxis: false },
              // { fieldName: 'SalesPerCap', modifier: 'vs. PY', mode: 'line', markers: false, lineWidth: 1, secondaryAxis: true },
            ],
            // series: 'Channel', // Not supported for now (not sure it's needed)
            cumulative: controls?.toggleCumulative?.selection,
            highlightWeekends: true,
            format: '$.2f',
            height: 400,
            widthRatio: 2,
          }}
        />
      </main>
    </DataLoader>
  );
};

export default DCOCharts;
