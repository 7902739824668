/* eslint-disable import/prefer-default-export */
import { differenceInDays, format } from 'date-fns';

const getStateGroup = (SOR, State) => {
  if (SOR === 'International') return 'International';
  if (State === 'FL') return 'Domestic FL';
  return 'Domestic Non-FL';
};

const datasetParams = {
  mainFile: 'DCO_Bookings/Outputs/DCO_Bookings_reducedData.csv',
  runDetailsFile: 'DCO_Bookings/Outputs/DCO_Bookings_runDetails.csv',
  tableName: 'DCO_bookings',
  mainDateField: 'BookDate',
  headersRemaps: {
    BookDate: 'BookDate',
    EventStartDate: 'EventDate',
    Channel_Remapped: 'Channel',
    SOR: 'SOR',
    State: 'State',
    Item_Type: 'ItemType',
  },
  valuesRemap: {
    Reseller: {
      'ENTERTAINMENT BENEFITS GRP-EZ TIX': 'EBG',
    },
  },
  metrics: {
    SalesUnits: {
      fieldName: 'SalesUnits',
      format: {
        style: 'decimal',
        maximumFractionDigits: 0,
      },
    },
    SalesRevenues: {
      fieldName: 'SalesRevenues',
      format: {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      },
    },
    SalesPerCap: {
      fieldName: 'SalesPerCap',
      format: {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      },
    },
    BookingsUnits: {
      fieldName: 'BookingsUnits',
      format: {
        style: 'decimal',
        maximumFractionDigits: 0,
      },
    },
    BookingsRevenues: {
      fieldName: 'BookingsRevenues',
      format: {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      },
    },
    BookingsPerCap: {
      fieldName: 'BookingsPerCap',
      format: {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      },
    },
  },
  calculatedMetrics: {
    SalesPerCap: {
      numerator: 'SalesRevenues',
      denominator: 'SalesUnits',
    },
    BookingsPerCap: {
      numerator: 'BookingsRevenues',
      denominator: 'BookingsUnits',
    },
  },
  transformRow: (row) => ({
    ...row,
    StateGroup: getStateGroup(row.SOR, row.State),
    BookDate: format(new Date(row.BookDate), 'yyyy-MM-dd'),
    BookYear: format(new Date(row.BookDate), 'yyyy'),
    BookYearMonth: format(new Date(row.BookDate), 'yyyy-MM'),
    EventDate: format(new Date(row.EventDate), 'yyyy-MM-dd'),
    EventYear: format(new Date(row.EventDate), 'yyyy'),
    EventYearMonth: format(new Date(row.EventDate), 'yyyy-MM'),
    Reseller: row.Reseller.toString(),
    leadTime: differenceInDays(new Date(row.BookDate), new Date(row.EventDate)),
  }),
  filterDimensions: {
    BookDate: {
      order: 'asc',
    },
    BookYear: {
      order: 'desc',
    },
    BookYearMonth: {
      order: 'desc',
    },
    EventDate: {
      order: 'asc',
    },
    EventYear: {
      order: 'desc',
    },
    EventYearMonth: {
      order: 'desc',
    },
    Channel: {
      order: 'asc',
      customOrder: ['Direct', 'Domestic Reseller', 'International Reseller', 'Vacation Packages'],
    },
    SOR: {
      order: 'asc',
      customOrder: ['Local', 'Same Day', 'Drive & Overnight', 'Domestic', 'International'],
    },
    StateGroup: {
      order: 'asc',
      customOrder: ['Domestic FL', 'Domestic Non-FL', 'International'],
    },
    State: {
      order: 'asc',
    },
    Reseller: {
      order: 'asc',
    },
    ItemType: {
      order: 'asc',
      customOrder: ['Swim', 'Swim P.', 'Swim U.', 'Day', 'Day P.', 'Day U.', 'Other'],
    },
  },
};

export default datasetParams;
