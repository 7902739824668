import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon, Spinner } from '@blueprintjs/core';
import './SimpleTable.css';
import innerText from 'react-innertext';

const SimpleTable = (props) => {
  const { selectOne, selectAll, headers, items, handleClickSelect, handleClickSelectAll } = props;

  const [sortedItems, setSortedItems] = React.useState([]);
  const [stateHeaders, setStateHeaders] = React.useState([]);

  // When items changes: set sortedItems
  React.useEffect(() => {
    setSortedItems(
      items.map((item) => ({
        components: item.components,
        key: item.key,
        checked: item.selected,
      })),
    );
  }, [items]);

  // When headers changes: set stateHeaders
  React.useEffect(() => {
    setStateHeaders(headers);
  }, [headers]);

  const handleSortItems = (headerID) => {
    const newSortedItems = sortedItems.concat().sort((a, b) => {
      if (innerText(a.components[headerID]) > innerText(b.components[headerID])) {
        return stateHeaders[headerID].sort === 'ascending' ? -1 : 1;
      }
      if (innerText(a.components[headerID]) < innerText(b.components[headerID])) {
        return stateHeaders[headerID].sort === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setSortedItems(newSortedItems);

    // Record the sorting to stateHeaders
    setStateHeaders([
      ...stateHeaders.slice(0, headerID).map((el) => ({ ...el, sort: null })),
      { ...stateHeaders[headerID], sort: stateHeaders[headerID].sort === 'ascending' ? 'descending' : 'ascending' },
      ...stateHeaders.slice(headerID + 1).map((el) => ({ ...el, sort: null })),
    ]);
  };

  return (
    <table className={`simpleTable ${props.className || ''}`} cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          {selectOne && (
            <th style={{ width: '5%', textAlign: 'center' }}>
              {selectAll && (
                <Checkbox
                  checked={sortedItems.length > 0 && sortedItems.filter((item) => !item.checked).length === 0}
                  indeterminate={sortedItems.filter((item) => !item.checked).length > 0 && sortedItems.filter((item) => item.checked).length > 0}
                  onChange={handleClickSelectAll}
                />
              )}
            </th>
          )}
          {stateHeaders.map((header, headerID) => (
            <th
              key={header.title}
              style={{ width: `${header.width * (selectOne ? 0.95 : 1)}%`, textAlign: header.alignHeader || ' center' }}
              onClick={() => handleSortItems(headerID)}
            >
              {header.sort ? <Icon style={{ margin: '0 4px 0 -20px' }} icon={header.sort === 'ascending' ? 'arrow-up' : 'arrow-down'} /> : null}
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      {props.isLoading ? (
        <tbody className="loading">
          <tr>
            <td colSpan={props.headers.length + 1} style={{ height: `${35 * props.items.length}px` }}>
              <Spinner size={25} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {sortedItems.map((item, itemID) => (
            <tr key={item.toString()}>
              {selectOne && (
                <td>
                  <Checkbox checked={item.checked} onChange={() => handleClickSelect(item.key)} />
                </td>
              )}
              {item &&
                item.components.map((el, i) => (
                  <td key={el.toString()} style={{ textAlign: stateHeaders[i].alignItems || 'center' }}>
                    {el}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

SimpleTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      alignHeader: PropTypes.string,
      alignItems: PropTypes.string,
      title: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      key: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(PropTypes.element).isRequired,
    }),
  ).isRequired,
  selectAll: PropTypes.bool,
  selectOne: PropTypes.bool,
  className: PropTypes.string,
  handleClickSelect: PropTypes.func,
  handleClickSelectAll: PropTypes.func,
  isLoading: PropTypes.bool,
};

SimpleTable.defaultProps = {
  className: '',
  selectAll: false,
  selectOne: false,
  handleClickSelect: () => {},
  handleClickSelectAll: () => {},
  isLoading: false,
};

export default SimpleTable;
