const appName = {
  dev: 'Orbital-dev',
  production: 'Orbital',
};

const sideBarItems = [
  {
    name: 'Data',
    displayName: 'Data',
    iconName: 'database',
    items: ['Data Generator', 'Upload', 'Datasets'],
  },
  // {
  //   name: 'Analysis',
  //   iconName: 'timeline-line-chart',
  //   items: ['Chart Builder'],
  //   // items: ['Dashboard', 'Standard Analyses', 'Custom Analyses', 'ROI Tree', 'Variance Waterfall']
  // },
  // {
  //   name: 'Planning',
  //   iconName: 'timeline-events',
  //   items: ['Overview', 'Calendar', 'Budget Optimizer'],
  // },
  // {
  //   name: 'Titan',
  //   displayName: 'Chart Builder',
  //   iconName: 'chart',
  //   items: ['Dashboards'],
  // },
  {
    name: 'Ganymede',
    displayName: 'Time Machine',
    iconName: 'comparison',
    items: ['Manage', 'Explorer', 'Prices'],
  },
  {
    name: 'Europa',
    displayName: 'Analytics',
    iconName: 'timeline-line-chart',
    items: ['Sales & Attendance'],
  },
  {
    name: 'Io',
    displayName: 'Web Analytics',
    iconName: 'globe-network',
    items: ['Traffic & Conversion', 'Weekly Reports'],
  },
  {
    name: 'Callisto',
    displayName: 'DCO',
    iconName: 'chart',
    items: ['DCO Bookings', 'DCO Weekly Reports', 'DCO Table', 'DCO Charts'],
  },
  // {
  //   name: 'Admin',
  //   displayName: 'Admin',
  //   iconName: 'lock',
  //   items: ['Users'],
  // },
];

const demoMode = false;

const buildNumberReset = process.env.REACT_APP_STAGE === 'production' ? 54 : 143;

export { appName, sideBarItems, demoMode, buildNumberReset };
