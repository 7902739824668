import React from 'react';
import DataLoader from 'Components/DataLoader/DataLoader';
import Filter from 'Components/Filter/Filter';
import OrbitalDataTableContainer from 'Components/OrbitalDataTable/OrbitalDataTableContainer';
import DateFilter from 'Components/DateFilter/DateFilter';
import { useSelector } from 'react-redux';
import datasetParams from '../utils/datasetParams';
import buildTableDefinition from '../utils/buildTableDefinition';

const DCOTable = () => {
  const controls = useSelector((store) => store.controlsReducer?.Callisto?.DCO_Table);
  const reportDate = controls?.reportDate;

  const tableDefinition = buildTableDefinition({
    columns: ['M-6', 'M-5', 'W-2', 'W-1', 'WTD'],
    dateField: 'BookDate',
    reportDate: reportDate?.selection.selectedDate,
    metrics: controls?.Metric?.selection.map((metric) => datasetParams.metrics[metric]),
    groupByDimensions: controls?.GroupBy?.selection.map((dim) => ({
      fieldName: dim,
      limit: 10,
      sortType: 'desc',
      overrideCustomOrder: false,
      showTotal: true,
    })),
    compYear: controls?.compYear?.selection,
    modifiers: controls?.modifier?.selection,
  });

  return (
    <DataLoader module="Callisto" subModule="DCO_Table" pageTitle="DCO Table" datasetParams={datasetParams}>
      <section className="topRow">
        <article className="controls">
          <section>
            <span style={{ width: '75px' }}>Report Date</span>
            <DateFilter module="Callisto" subModule="DCO_Table" controlName="reportDate" showDeleteSelection />
            <Filter module="Callisto" subModule="DCO_Table" controlName="Metric" placeholder="Metric..." values={Object.keys(datasetParams.metrics)} />
            <Filter
              module="Callisto"
              subModule="DCO_Table"
              controlName="GroupBy"
              placeholder="Group By..."
              multiple
              values={Object.keys(datasetParams.filterDimensions)}
            />
            <Filter module="Callisto" subModule="DCO_Table" controlName="compYear" placeholder="Comp Year..." width={100} values={['PY', '2PY']} />
            <Filter
              module="Callisto"
              subModule="DCO_Table"
              controlName="modifier"
              placeholder="Modifiers..."
              multiple
              width={360}
              values={['CY', '[Comp Year]', 'vs. [Comp Year]', 'vs. [Comp Year] (%)']}
            />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCO_Table" controlName="Channel" placeholder="Channel..." multiple />
            <Filter module="Callisto" subModule="DCO_Table" controlName="ItemType" placeholder="Item Type..." multiple />
            <Filter module="Callisto" subModule="DCO_Table" controlName="SOR" placeholder="SOR..." multiple />
            <Filter module="Callisto" subModule="DCO_Table" controlName="StateGroup" placeholder="State Group..." multiple />
            <Filter module="Callisto" subModule="DCO_Table" controlName="Reseller" placeholder="Reseller..." multiple />
          </section>
        </article>
      </section>
      {reportDate && reportDate?.selection?.selectedDate && (
        <main>
          <OrbitalDataTableContainer
            title={`${controls?.Metric?.selection[0]} by ${controls?.GroupBy?.selection.join(', ')}`}
            module="Callisto"
            subModule="DCO_Table"
            datasetParams={datasetParams}
            tableDefinition={tableDefinition}
          />
        </main>
      )}
    </DataLoader>
  );
};

export default DCOTable;
