import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@blueprintjs/core';
import './SideBar.css';
import { useLocation } from 'react-router-dom';
import MenuGroup from './MenuGroup';

const SideBar = ({ items, modulesAccess }) => {
  const [activeGroup, setActiveGroup] = React.useState(['']);
  const [loadedItem, setLoadedItem] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  // When items changes, validate that there are no duplicates (show warning if so)
  const location = useLocation();

  // When mounting or when the URL changes: set the loaded item corresponding to the URL
  React.useEffect(() => {
    for (const item of items) {
      for (const subItem of item.items) {
        const pathName = `/${encodeURI(item.name)}/${encodeURI(subItem)}`;
        if (pathName === window.location.pathname) {
          setLoadedItem(pathName);
        }
      }
    }
  }, [items, location.pathname]);

  const handleClickGroupTitle = (groupTitle) => {
    if (isOpen) {
      if (groupTitle === activeGroup) {
        setActiveGroup('');
      } else {
        setActiveGroup(groupTitle);
      }
    }
  };
  const handleClickLink = (groupName, itemPathName) => {
    setActiveGroup(groupName);
    setLoadedItem(itemPathName);
  };
  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  // Build the list of modules and submodules user has access to
  let itemsToShow = [];
  if (modulesAccess.admin) {
    itemsToShow = items;
  } else {
    itemsToShow = items
      .filter((item) => Object.keys(modulesAccess.modules).includes(item.name))
      .map((topLevelItem) => {
        // console.log(modulesAccess.modules[topLevelItem.name]);
        if (modulesAccess.modules[topLevelItem.name] === true) return items.find((el) => el.name === topLevelItem.name);
        return {
          ...topLevelItem,
          items: topLevelItem.items.filter((item) => modulesAccess.modules[topLevelItem.name][item] === true),
        };
      });
  }

  // console.log({ items, modulesAccess, itemsToShow });

  return (
    <div className={`SideBar ${isOpen ? 'open' : ''}`}>
      <Button className="openCloseButton" minimal onClick={handleOpenClose}>
        <Icon icon={isOpen ? 'cross' : 'menu'} iconSize="25" />
      </Button>
      {itemsToShow.map((item, id) => (
        <MenuGroup
          key={item.name}
          name={item.name}
          displayName={item.displayName}
          moduleAccess={modulesAccess.admin ? { admin: true } : modulesAccess.modules[item.name]}
          active={item.name === activeGroup}
          iconName={item.iconName}
          items={item.items}
          sidebarIsOpen={isOpen}
          loadedItem={loadedItem}
          // loadedItem={item.name === activeGroup ? loadedItem : null}
          handleClickGroupTitle={handleClickGroupTitle}
          handleClickLink={handleClickLink}
          calculatedTop={`${(121 + id * 55).toString()}px`}
        />
      ))}
      {isOpen ? <footer className="buildNumber">{`Build: ${process.env.REACT_APP_BUILD_NUMBER || 'local'}`}</footer> : null}
    </div>
  );
};

SideBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  modulesAccess: PropTypes.shape({
    admin: PropTypes.bool,
    modules: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])),
  }),
};

SideBar.defaultProps = {
  modulesAccess: {
    admin: false,
    modules: [],
  },
};

export default SideBar;
