/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import MyBreadCrumbs from '../../../Components/MyBreadCrumbs/MyBreadCrumbs';
import ExplorerFiltersContainer from './ExplorerFilters/ExplorerFiltersContainer';
import ExplorerListContainer from './ExplorerList/ExplorerListContainer';
import { screenshotPropTypes } from '../Definitions/ganymedePropTypes';
import ScreenshotOvelay from './ScreenshotOvelay/ScreenshotOvelay';
import ExplorerControlsContainer from './ExplorerControls/ExplorerControlsContainer';
import { MAX_ITEMS_LIST } from '../utils/constants';

const Explorer = (props) => (
  <div className="Explorer">
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <MyBreadCrumbs
        items={[
          { href: '/', text: 'Home' },
          { href: '/Ganymede', text: 'Time Machine' },
          { href: '/Ganymede/Explorer', text: 'Explorer' },
        ]}
      />
      {props.isUpdating && (
        <div style={{ float: 'left', margin: '5px 5px 5px 15px' }}>
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      )}
    </div>
    <div className="PageContainer">
      {/* <h2 style={{ float: 'left' }}>Ganyemede - Explorer</h2> */}
      <ExplorerFiltersContainer />
      <ExplorerControlsContainer
        screenshots={props.screenshots}
        updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
        selectedScreenshotKeys={props.selectedScreenshotKeys}
        setDeletingLocalCopyKeys={props.setDeletingLocalCopyKeys}
        localNbOfScreenshots={props.localNbOfScreenshots}
        handleCompareScreenshots={props.handleCompareScreenshots}
        copyLinkToClipboard={props.copyLinkToClipboard}
      />
      <ExplorerListContainer
        isUpdating={props.isUpdating}
        screenshots={props.screenshots.slice(0, MAX_ITEMS_LIST)}
        handleSelectScreenshot={props.handleSelectScreenshot}
        handleSelectAllScreenshots={props.handleSelectAllScreenshots}
        selectedScreenshotKeys={props.selectedScreenshotKeys}
        handleSortItems={props.handleSortItems}
        sorting={props.sorting}
        displayScreenshot={props.displayScreenshot}
        showOverlayScreenshots={props.showOverlayScreenshots}
        deletingLocalCopyKeys={props.deletingLocalCopyKeys}
        localNbOfScreenshots={props.localNbOfScreenshots}
        updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
        copyLinkToClipboard={props.copyLinkToClipboard}
      />
      <ScreenshotOvelay
        imgData={props.overlayImgData}
        isOpen={props.screenshotOverlayIsOpen}
        handleClose={props.handleCloseScreenshotOverlay}
        updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
      />
    </div>
  </div>
);

Explorer.propTypes = {
  isUpdating: PropTypes.bool,
  screenshots: PropTypes.arrayOf(screenshotPropTypes),
  handleSelectScreenshot: PropTypes.func.isRequired,
  handleSelectAllScreenshots: PropTypes.func.isRequired,
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSortItems: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    attribute: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
  }).isRequired,
  displayScreenshot: PropTypes.func.isRequired,
  screenshotOverlayIsOpen: PropTypes.bool.isRequired,
  handleCloseScreenshotOverlay: PropTypes.func.isRequired,
  overlayImgData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
      src: PropTypes.string,
    }),
  ).isRequired,
  showOverlayScreenshots: PropTypes.func.isRequired,
  handleCompareScreenshots: PropTypes.func.isRequired,
  deletingLocalCopyKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeletingLocalCopyKeys: PropTypes.func.isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

Explorer.defaultProps = {
  isUpdating: false,
  screenshots: [],
};

export default Explorer;
