import React from 'react';
import PropTypes from 'prop-types';
import { Button, H5, HTMLTable } from '@blueprintjs/core';
import './OrbitalDataTable.css';

const OrbitalDataTable = (props) => (
  <div className="OrbitalDataTable">
    <header>
      <H5>{props.title}</H5>
      <Button className="copyToClipboard" onClick={props.copyToClipboard} icon="clipboard" minimal small />
    </header>
    <hr />
    <HTMLTable className={props.isLoading && 'isLoading'} bordered interactive width="100%" style={{ fontSize: 12 }}>
      <thead>
        {props.headers.map((headerRow) => (
          <tr key={headerRow.map((el) => el.value).join('')}>
            {headerRow.map((header, colNb) => (
              <th key={props.headerKeys[colNb]} className="rowHeader" colSpan={header.colSpan || 1}>
                {header.value}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {props.data.map((row) => (
          <tr key={row.join('')} className={row.join('').startsWith('Total') || row.join('').startsWith('Sub-total') ? 'totalRow' : ''}>
            {row.map((cell, colNb) => {
              const { value, format } = cell;
              const cellFormated = !Number.isFinite(value) || Number.isNaN(value) ? '-' : new Intl.NumberFormat('en-US', format).format(value);
              return (
                <td
                  key={`${props.headerKeys[colNb]}_${value}`}
                  className={`${value < 0 ? 'negative' : ''} ${colNb < props.nbDimensions ? 'columnHeader' : ''}`}
                >
                  {/* {colNb < props.nbDimensions ? cell : cellFormated} */}
                  {typeof cell === 'object' ? cellFormated : cell}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  </div>
);

OrbitalDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.number,
          format: PropTypes.shape({
            style: PropTypes.string,
            maximumFractionDigits: PropTypes.number,
            signDisplay: PropTypes.string,
            currency: PropTypes.string,
          }),
        }),
      ]),
    ),
  ).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.arrayOf(
      // PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        colSpan: PropTypes.number,
      }),
    ),
  ).isRequired,
  headerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  nbDimensions: PropTypes.number.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
};

export default OrbitalDataTable;
