/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Intent, Toaster } from '@blueprintjs/core';
// import { getHeaders } from './functions/dataHelpers';
import LinesChart from 'Components/LineChart/LinesChart';
import { format } from 'date-fns';

const copyDataToClipboardToaster = Toaster.create();

const OrbitalDataChart = (props) => {
  // if (props.tableDefinition.length === 0) return null;
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);
  // get all filter values
  const controls = useSelector((store) => store.controlsReducer && store.controlsReducer[props.module] && store.controlsReducer[props.module][props.subModule]);
  // console.log({ controls });
  const dataInfo = useSelector((store) => store.dataReducer && store.dataReducer[props.module] && store.dataReducer[props.module][props.subModule]?.dataInfo);
  // console.log(dataInfo);

  // STATE
  const [workerBusy, setWorkerBusy] = React.useState(false);
  const [data, setChartData] = React.useState([]);
  // console.log({ data });

  // DERIVED STATE
  const title = props.title || `${props.chartDefinition.yAxis[0].fieldName}`;
  // const nbDimensions = props.chartDefinition.groupByDimensions.length;
  const { tableName, calculatedMetrics, filterDimensions } = props.datasetParams;
  // const headers_raw = props.chartDefinition.map((chartDef) => chartDef.columnHeaders);
  // const { headers, headerKeys } = getHeaders(headers_raw, nbDimensions);

  // SHARED WORKER
  const sharedWorker = React.useMemo(() => new SharedWorker(new URL('Data/engineWorker.js', import.meta.url /* webpackChunkName: "my-worker" */)), []);
  sharedWorker.port.start();

  // UPDATE DATA WHEN CONTROLS CHANGE
  React.useEffect(() => {
    if (dataInfo) {
      // Don't ask for data if there is no data
      const filters = [];
      for (const [controlName, filter] of Object.entries(controls)) {
        const fieldName = controlName.split('.')[0];
        // Skip if field is not in the filterDimensions
        if (filterDimensions[fieldName]) {
          if (filter.selection?.length > 0 || filter.selection?.from || filter.selection?.to || filter.selection?.selectedDate) {
            let type;
            if (filter.selection?.from || filter.selection?.to || filter.selection.selectedDate) {
              type = 'date';
            } else if (fieldName.toLocaleLowerCase().includes('year') || fieldName.toLocaleLowerCase().includes('month')) {
              // needed because for these the dates need to be shifted for prior years
              type = 'date-list';
            } else {
              type = 'list';
            }

            filters.push({ fieldName, type, values: filter.selection });
          }
        }
      }
      // console.log({ filters });

      const { cumulative } = props.chartDefinition;
      const requests = props.chartDefinition.yAxis.map((yAxis) => {
        const { fieldName, ...rest_yAxis } = yAxis;
        return {
          metric: yAxis.fieldName,
          ...rest_yAxis,
          filters,
          groupByDimensions: [props.chartDefinition.xAxis],
          cumulative,
        };
      });
      // console.log({ request });

      if (!workerBusy) {
        // temporary to avoid recalculating the same things just because of unnecessary re-renders
        setWorkerBusy(true);
        sharedWorker.port.postMessage({
          type: 'getChartData',
          datasetParams: { tableName, datasetDimensions: filterDimensions, calculatedMetrics },
          requests,
        });
      }
    }
  }, [controls, dataInfo]);

  // React to message getting the chart data
  React.useEffect(() => {
    sharedWorker.port.onmessage = (e) => {
      const { type, chartData } = e.data;
      if (type !== 'getChartData') return;
      setChartData(chartData);
      setWorkerBusy(false);
    };
  }, []);

  const copyToClipboard = async () => {
    const unqiueDates = [...new Set(data.map((el) => el.x).flat())];
    const headers = [props.chartDefinition.xAxis.fieldName, ...data.map((series, i) => `${props.chartDefinition.yAxis[i].fieldName} - ${series.name}`)];
    const copyData = unqiueDates.map((date) => {
      const row = [date];
      data.forEach((series) => {
        const index = series.x.findIndex((x) => x === date);
        row.push(series.y[index]);
      });
      return row;
    });

    const text = [headers, ...copyData].map((row) => row.join('\t')).join('\n');
    // console.log(text);
    await navigator.clipboard.writeText(text);
    copyDataToClipboardToaster.show({ message: 'Data succesfully copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
  };

  return (
    data?.length > 0 && (
      <LinesChart
        title={props.title}
        data={data}
        copyToClipboard={copyToClipboard}
        height={props.chartDefinition.height}
        widthRatio={props.chartDefinition.widthRatio}
        isDarkMode={isDarkMode}
        highlightWeekends={props.chartDefinition.xAxis.modifier === 'Day' && props.chartDefinition.highlightWeekends}
        rangeSlider={props.chartDefinition.rangeSlider}
        format={props.chartDefinition.format}
      />
    )
  );
};

OrbitalDataChart.propTypes = {
  title: PropTypes.string,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  chartDefinition: PropTypes.shape({
    xAxis: PropTypes.shape({
      fieldName: PropTypes.string,
      modifier: PropTypes.string,
    }),
    yAxis: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
        modifier: PropTypes.string,
        lineWidth: PropTypes.number,
        markers: PropTypes.bool,
        secondaryAxis: PropTypes.bool,
      }),
    ),
    // series: PropTypes.string.isRequired, // not implemented yet (TBD if really useful)
    chartFilters: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
        type: PropTypes.string,
        dateType: PropTypes.string,
        values: PropTypes.shape({
          fromFn: PropTypes.func,
          toFn: PropTypes.func,
        }),
      }),
    ),
    cumulative: PropTypes.bool,
    height: PropTypes.number.isRequired,
    widthRatio: PropTypes.number.isRequired,
    highlightWeekends: PropTypes.bool.isRequired,
    format: PropTypes.string,
    rangeSlider: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }).isRequired,
  datasetParams: PropTypes.shape({
    mainFile: PropTypes.string.isRequired,
    runDetailsFile: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    mainDateField: PropTypes.string,
    headersRemaps: PropTypes.objectOf(PropTypes.string),
    calculatedMetrics: PropTypes.shape({
      numerator: PropTypes.string,
      denominator: PropTypes.string,
    }),
    transformRow: PropTypes.func,
    filterDimensions: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};

OrbitalDataChart.defaultProps = {
  title: '',
};

export default OrbitalDataChart;
