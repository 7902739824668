const sortPerList = (arr, sortOrder, keepOrigOrder = false) => {
  if (!sortOrder) return arr.sort();

  const itemsInSortOrder = sortOrder.filter((el) => arr.includes(el));
  const itemsNotInSortOrder = arr.filter((el) => !itemsInSortOrder.includes(el));
  const otherItems = keepOrigOrder ? itemsNotInSortOrder : itemsNotInSortOrder.sort();
  return [...itemsInSortOrder, ...otherItems];
};

const cartesianProduct = (...a) => a.reduce((b, c) => b.flatMap((d) => c.map((e) => [d, e].flat())));

const transposeArray = (arr) => arr[0].map((col, i) => arr.map((row) => row[i]));

export { sortPerList, cartesianProduct, transposeArray };
