import React from 'react';
import DataLoader from 'Components/DataLoader/DataLoader';
import Filter from 'Components/Filter/Filter';
import OrbitalDataTableContainer from 'Components/OrbitalDataTable/OrbitalDataTableContainer';
import DateFilter from 'Components/DateFilter/DateFilter';
import { useSelector } from 'react-redux';
import datasetParams from '../utils/datasetParams';
import buildTableDefinition from '../utils/buildTableDefinition';

const SUB_MODULE = 'DCO_Weekly_Reports';

const DCOWeeklyReports = () => {
  const controls = useSelector((store) => store.controlsReducer?.Callisto[SUB_MODULE]);
  const reportDate = controls?.reportDate;

  const baseTable_def = {
    reportDate: reportDate?.selection.selectedDate,
    columns: ['M-6', 'M-5', 'M-4', 'M-3', 'M-2', 'M-1', 'W-3', 'W-2', 'W-1', 'WTD', 'L3W'],
    dateField: 'BookDate',
    groupByDimensions: [{ fieldName: 'Channel' }],
    modifiers: ['vs. PY (%)'],
    compYear: controls?.compYear?.selection,
  };

  return (
    <DataLoader module="Callisto" subModule={SUB_MODULE} pageTitle="DCO Weekly Reports" datasetParams={datasetParams}>
      <section className="topRow">
        <article className="controls">
          <section>
            <span style={{ width: '75px' }}>Report Date</span>
            <DateFilter module="Callisto" subModule={SUB_MODULE} controlName="reportDate" showDeleteSelection />
            <Filter module="Callisto" subModule={SUB_MODULE} controlName="compYear" placeholder="Comp Year..." width={100} values={['PY', '2PY']} />
          </section>
        </article>
      </section>
      {reportDate && reportDate?.selection?.selectedDate && (
        <main>
          <OrbitalDataTableContainer
            title="Sales by Channel"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({ ...baseTable_def, metrics: [datasetParams.metrics.SalesUnits] })}
          />
          <OrbitalDataTableContainer
            title="Sales Revenues by Channel"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({ ...baseTable_def, metrics: [datasetParams.metrics.SalesRevenues] })}
          />
          <OrbitalDataTableContainer
            title="Sales per cap by Channel"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({ ...baseTable_def, metrics: [datasetParams.metrics.SalesPerCap] })}
          />
          <OrbitalDataTableContainer
            title="Sales by Product"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              groupByDimensions: [{ fieldName: 'ItemType' }],
              metrics: [datasetParams.metrics.SalesUnits],
            })}
          />
          <OrbitalDataTableContainer
            title="Sales Revenues by Product"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              groupByDimensions: [{ fieldName: 'ItemType' }],
              metrics: [datasetParams.metrics.SalesRevenues],
            })}
          />
          <OrbitalDataTableContainer
            title="Sales per cap by Product"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              groupByDimensions: [{ fieldName: 'ItemType' }],
              metrics: [datasetParams.metrics.SalesPerCap],
            })}
          />
          <OrbitalDataTableContainer
            title="Sales by Domestic Reseller"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              tableFilters: [{ type: 'list', fieldName: 'Channel', values: ['Domestic Reseller'] }],
              groupByDimensions: [{ fieldName: 'Reseller', type: 'list', limit: 6 }],
              metrics: [datasetParams.metrics.SalesUnits],
            })}
          />
          <OrbitalDataTableContainer
            title="Sales Revenues by Domestic Reseller"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              tableFilters: [{ type: 'list', fieldName: 'Channel', values: ['Domestic Reseller'] }],
              groupByDimensions: [{ fieldName: 'Reseller', type: 'list', limit: 6 }],
              metrics: [datasetParams.metrics.SalesRevenues],
            })}
          />
          <OrbitalDataTableContainer
            title="Sales per cap by Domestic Reseller"
            module="Callisto"
            subModule={SUB_MODULE}
            datasetParams={datasetParams}
            tableDefinition={buildTableDefinition({
              ...baseTable_def,
              tableFilters: [{ type: 'list', fieldName: 'Channel', values: ['Domestic Reseller'] }],
              groupByDimensions: [{ fieldName: 'Reseller', type: 'list', limit: 6 }],
              metrics: [datasetParams.metrics.SalesPerCap],
            })}
          />
        </main>
      )}
    </DataLoader>
  );
};

export default DCOWeeklyReports;
