/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { transposeArray } from 'utils/utils';
import { Intent, Toaster } from '@blueprintjs/core';
import { getHeaders } from './functions/dataHelpers';
import OrbitalDataTable from './OrbitalDataTable';

const copyDataToClipboardToaster = Toaster.create();

const OrbitalDataTableContainer = (props) => {
  if (props.tableDefinition.length === 0) return null;
  // get all filter values
  const controls = useSelector((store) => store.controlsReducer && store.controlsReducer[props.module] && store.controlsReducer[props.module][props.subModule]);
  // console.log({ controls });
  const dataInfo = useSelector((store) => store.dataReducer && store.dataReducer[props.module] && store.dataReducer[props.module][props.subModule]?.dataInfo);
  // console.log(dataInfo);

  // STATE
  const [workerBusy, setWorkerBusy] = React.useState(false);
  const [data, setTableData] = React.useState([]);

  // DERIVED STATE
  const title = props.title || `${props.tableDefinition[0].metric} by ${props.tableDefinition[0].groupByDimensions.map((el) => el.field).join(', ')}`;
  const nbDimensions = props.tableDefinition[0].groupByDimensions.length;

  const { tableName, calculatedMetrics, filterDimensions } = props.datasetParams;
  const headers_raw = props.tableDefinition.map((tableDef) => tableDef.columnHeaders);
  const { headers, headerKeys } = getHeaders(headers_raw, nbDimensions);
  // SHARED WORKER
  const sharedWorker = React.useMemo(() => new SharedWorker(new URL('Data/engineWorker.js', import.meta.url /* webpackChunkName: "my-worker" */)), []);
  sharedWorker.port.start();

  // UPDATE DATA WHEN CONTROLS CHANGE
  React.useEffect(() => {
    if (dataInfo) {
      // Don't ask for data if there is no data
      const filters = [];
      for (const [fieldName, filter] of Object.entries(controls)) {
        // Skip if field is not in the filterDimensions
        if (filterDimensions[fieldName]) {
          if (filter.selection?.length > 0 || filter.selection?.from || filter.selection?.to) {
            let type = 'list';
            if (filter.selection?.from || filter.selection?.to) type = 'date';
            filters.push({ fieldName, type, values: filter.selection });
          }
        }
      }
      // console.log({ filters });w

      const requests = props.tableDefinition.map((tableDef) => ({
        ...tableDef,
        filters: [tableDef.columnFilter, ...(tableDef.tableFilters || []), ...filters],
      }));
      // console.log({ requests });

      if (!workerBusy) {
        // temporary to avoid recalculating the same things just because of unnecessary re-renders
        setWorkerBusy(true);
        sharedWorker.port.postMessage({
          type: 'getTableData',
          datasetParams: { tableName, datasetDimensions: filterDimensions, calculatedMetrics },
          requests,
        });
      }
    }
  }, [controls, dataInfo]);

  // React to message getting the table data
  React.useEffect(() => {
    sharedWorker.port.onmessage = (e) => {
      const { type, tableData } = e.data;
      switch (type) {
        case 'getTableData':
          setTableData({ data: tableData, headers, headerKeys });
          setWorkerBusy(false);
          break;
        default:
          break;
      }
    };
  }, []);

  const copyToClipboard = async () => {
    const headers_augmented = [...Array(nbDimensions).fill(Array(headers_raw[0].length).fill('')), ...headers_raw];
    const text = [...transposeArray(headers_augmented), ...data.data]
      .map((row) =>
        row
          .map((cell) => {
            if (typeof cell === 'object') {
              const { value, format } = cell;
              const cellFormated = !Number.isFinite(value) || Number.isNaN(value) ? '-' : new Intl.NumberFormat('en-US', format).format(value);
              return cellFormated;
            }
            return cell;
          })
          .join('\t'),
      )
      .join('\n');
    await navigator.clipboard.writeText(text);
    copyDataToClipboardToaster.show({
      message: 'Data succesfully copied to clipboard',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  return (
    data?.data?.length > 0 && (
      <OrbitalDataTable
        title={title}
        data={data.data}
        headers={headers}
        headerKeys={headerKeys}
        nbDimensions={nbDimensions}
        copyToClipboard={copyToClipboard}
        isLoading={workerBusy}
      />
    )
  );
};

OrbitalDataTableContainer.propTypes = {
  title: PropTypes.string,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  tableDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
      modifier: PropTypes.string,
      tableFilters: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string,
          type: PropTypes.string,
          dateType: PropTypes.string,
          values: PropTypes.oneOfType([
            PropTypes.shape({
              fromFn: PropTypes.func,
              toFn: PropTypes.func,
            }),
            PropTypes.arrayOf(PropTypes.string),
          ]),
        }),
      ),
      columnFilter: PropTypes.shape({
        field: PropTypes.string,
        type: PropTypes.string,
      }),
      columnHeaders: PropTypes.arrayOf(PropTypes.string),
      groupByDimensions: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          limit: PropTypes.number,
          sortBy: PropTypes.string,
          overrideCustomOrder: PropTypes.bool,
          total: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  datasetParams: PropTypes.shape({
    mainFile: PropTypes.string.isRequired,
    runDetailsFile: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    mainDateField: PropTypes.string,
    headersRemaps: PropTypes.objectOf(PropTypes.string),
    calculatedMetrics: PropTypes.shape({
      numerator: PropTypes.string,
      denominator: PropTypes.string,
    }),
    transformRow: PropTypes.func,
    filterDimensions: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};

OrbitalDataTableContainer.defaultProps = {
  title: '',
};

export default OrbitalDataTableContainer;
