/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import { explorerTableHeaders } from 'AppModules/Ganymede/utils/constants';
import ExplorerList from './ExplorerList';

const ExplorerListContainer = (props) => {
  const { screenshotsListIsUpdating } = useSelector((store) => store.ganymedeReducer);

  const [aScreenshotIsLoading, setAScreenshotIsLoading] = React.useState(false);

  return (
    <ExplorerList
      screenshots={props.screenshots}
      tableHeaders={explorerTableHeaders}
      handleSortItems={props.handleSortItems}
      sorting={props.sorting}
      selectedScreenshotKeys={props.selectedScreenshotKeys}
      handleSelectScreenshot={props.handleSelectScreenshot}
      handleSelectAllScreenshots={props.handleSelectAllScreenshots}
      deletingLocalCopyKeys={props.deletingLocalCopyKeys}
      aScreenshotIsLoading={aScreenshotIsLoading}
      setAScreenshotIsLoading={setAScreenshotIsLoading}
      handleDisplayScreenshot={props.displayScreenshot}
      showOverlayScreenshots={props.showOverlayScreenshots}
      localNbOfScreenshots={props.localNbOfScreenshots}
      updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
      screenshotsListIsUpdating={screenshotsListIsUpdating}
      copyLinkToClipboard={props.copyLinkToClipboard}
    />
  );
};

ExplorerListContainer.propTypes = {
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  handleSelectAllScreenshots: PropTypes.func.isRequired,
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSortItems: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    attribute: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
  }).isRequired,
  displayScreenshot: PropTypes.func.isRequired,
  showOverlayScreenshots: PropTypes.func.isRequired,
  deletingLocalCopyKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

export default ExplorerListContainer;
