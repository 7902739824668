import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import localforage from 'localforage';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import { Toaster, Intent } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import ganymedeAPI from 'AppModules/Ganymede/API/ganymedeAPI';
import { formatDate, sub } from 'date-fns';
import ScreenshotRow from './ScreenshotRow';

const infoToaster = Toaster.create();
const downloadErrorToaster = Toaster.create();

const ScreenshotRowContainer = (props) => {
  const token = useSelector((store) => store.authReducer.token || '');
  const dispatch = useDispatch();

  const [downloadProgress, setDownloadProgress] = React.useState();
  const [screenshotImgData, setScreenshotImgData] = React.useState('');

  // When mounting, load imgData from localforage if it exists
  React.useEffect(() => {
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted
    const getLocalImgData = async () => {
      const imgData = await localforage.getItem(`ganymedeScreenshotData_${props.screenshot.key}`);
      if (isSubscribed) {
        if (imgData) {
          setScreenshotImgData(imgData);
          setDownloadProgress(1);
        } else {
          setScreenshotImgData('');
          setDownloadProgress(null);
        }
      }
    };
    getLocalImgData();
    return () => {
      isSubscribed = false;
    };
  }, [props.isDeleting, props.screenshot.key, props.localNbOfScreenshots]);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  const downloadScreenshot = async () => {
    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    const { imgData, err } = await ganymedeActions.downloadScreenshot(props.screenshot.key, onDownloadProgress, props.token);
    if (!err) {
      setScreenshotImgData(imgData);
      props.updateNbOfLocalScreenshotKeys();
    } else {
      downloadErrorToaster.show({ message: err, intent: Intent.DANGER, icon: 'warning-sign' });
      setDownloadProgress(null);
    }
    return { imgData, err };
  };

  const handleClickDownloadScreenshot = async (e) => {
    e.stopPropagation();
    await downloadScreenshot();
  };

  const handleClickCompareScreenshot = async (e) => {
    e.stopPropagation();
    const { park, pageType, version, device, dateStr } = props.screenshot;
    // get screenshot metadata from server
    const otherScreenshotRequest = {
      Park: [park],
      Page: [pageType],
      Version: [version],
      Device: [device],
      additionalSnapshotDates: [],
      snapshotDate: {
        from: sub(new Date(dateStr), { days: 364 }),
        to: sub(new Date(dateStr), { days: 364 }),
      },
    };
    const theOtherScreenshot = (await ganymedeAPI.getScreenshots(token, otherScreenshotRequest)).items[0];
    const screenshots = [props.screenshot, theOtherScreenshot];

    if (theOtherScreenshot) {
      await props.showOverlayScreenshots(screenshots);
    } else {
      infoToaster.show({ message: 'No other screenshot found to compare with', intent: Intent.WARNING, icon: 'warning-sign' });
    }
  };

  const handleClickShowScreenshot = async () => {
    if (screenshotImgData) {
      props.handleDisplayScreenshot({ ...screenshotImgData, screenshot: props.screenshot });
    } else if (downloadProgress === null || downloadProgress === undefined) {
      // screenshot hasn't be downloaded yet
      if (!props.aScreenshotIsLoading) {
        props.setAScreenshotIsLoading(true);
        // if (screenshotImgData) {
        //   props.handleDisplayScreenshot(screenshotImgData);
        //   props.setAScreenshotIsLoading(false);
        // } else {
        const { imgData, err } = await downloadScreenshot();
        if (!err) {
          props.handleDisplayScreenshot({ ...imgData, screenshot: props.screenshot });
          props.setAScreenshotIsLoading(false);
        } else {
          props.setAScreenshotIsLoading(false);
          setDownloadProgress(null);
        }
        // }
      } else {
        infoToaster.show({ message: 'Another screenshot is already loading', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    } // otherwise screenshot is currently loading, do nothing
  };

  return (
    <ScreenshotRow
      screenshot={props.screenshot}
      selected={props.selected}
      isDeleting={props.isDeleting}
      handleSelectScreenshot={props.handleSelectScreenshot}
      handleClickDownloadScreenshot={handleClickDownloadScreenshot}
      downloadProgress={downloadProgress}
      handleClickShowScreenshot={handleClickShowScreenshot}
      handleClickCompareScreenshot={handleClickCompareScreenshot}
      copyLinkToClipboard={props.copyLinkToClipboard}
    />
  );
};

ScreenshotRowContainer.propTypes = {
  token: PropTypes.string.isRequired,
  screenshot: screenshotPropTypes.isRequired,
  selected: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  aScreenshotIsLoading: PropTypes.bool.isRequired,
  setAScreenshotIsLoading: PropTypes.func.isRequired,
  handleDisplayScreenshot: PropTypes.func.isRequired,
  showOverlayScreenshots: PropTypes.func.isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired, // only present to force a re-render when screenhots are downloaded in the Overlay
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
});

export default connect(mapStateToProps)(ScreenshotRowContainer);
