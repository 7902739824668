/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Spinner, SpinnerSize } from '@blueprintjs/core';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import PricesFiltersContainer from './PricesFilters/PricesFiltersContainer';
import PricesChartContainer from './PricesChart/PricesChartContainer';

const Prices = (props) => (
  <div className="Prices">
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <MyBreadCrumbs
          items={[
            { href: '/', text: 'Home' },
            { href: '/Ganymede', text: 'Time Machine' },
            { href: '/Ganymede/Prices', text: 'Prices' },
          ]}
        />
        {/* <h2 style={{ float: 'left' }}>Ganyemede - Prices</h2> */}
        {props.isUpdating && (
          <div style={{ margin: '18px 5px 5px 5px' }}>
            <Spinner size={SpinnerSize.SMALL} />
          </div>
        )}
      </div>
      <ButtonGroup style={{ height: '25px', margin: '13px 10px 0 0' }}>
        <Button active={props.priceMode === 'bySnapshotDate'} onClick={() => props.udpatePriceMode('bySnapshotDate')}>
          By Snapshot Date
        </Button>
        <Button active={props.priceMode === 'byEventDate'} onClick={() => props.udpatePriceMode('byEventDate')}>
          By Event Date
        </Button>
      </ButtonGroup>
    </div>
    <div className="PageContainer">
      <PricesFiltersContainer />
      {props.isUpdating ? (
        <p>Loading...</p>
      ) : (
        <PricesChartContainer
          title="SDT price"
          priceMode={props.priceMode}
          data={props.pricesData}
          width={window.innerWidth - 120}
          height={500}
          copyDataToClipboard={props.copyDataToClipboard}
        />
      )}
    </div>
  </div>
);

Prices.propTypes = {
  isUpdating: PropTypes.bool,
  priceMode: PropTypes.string.isRequired,
  udpatePriceMode: PropTypes.func.isRequired,
  pricesData: PropTypes.any.isRequired,
  copyDataToClipboard: PropTypes.func.isRequired,
};

Prices.defaultProps = {
  isUpdating: false,
};

export default Prices;
