import dateColumns from 'utils/dateColumns';

const buildTableDefinition = ({
  reportDate,
  metrics,
  tableFilters = [],
  columns,
  dateField,
  compYear,
  modifiers,
  groupByDimensions,
  showGrandTotal = true,
}) => {
  if (!metrics || metrics.length === 0) return [];
  if (!compYear || compYear === '') return [];
  if (!modifiers || modifiers.length === 0) return [];
  if (!groupByDimensions || groupByDimensions.length === 0) return [];

  const output = [];
  // console.log({ columns });

  const tableFilters_calculated = tableFilters?.map((filter) => {
    const { fieldName, type, dateType, values } = filter;
    let values_calculated = values;
    if (dateType === 'relative') {
      values_calculated = {
        from: values.fromFn(reportDate),
        to: values.toFn(reportDate),
      };
    }
    return { fieldName, type, values: values_calculated };
  });

  const groupByDimensions_adjusted = groupByDimensions.map((dim) => {
    if (typeof dim === 'string') {
      return { fieldName: dim, limit: 10, sortType: 'desc', overrideCustomOrder: false, showTotal: true };
    }
    const { fieldName, limit, sortType, overrideCustomOrder, showTotal } = dim;
    return { fieldName, limit: limit || 10, sortType: sortType || 'desc', overrideCustomOrder: overrideCustomOrder || false, showTotal: showTotal || true };
  });

  for (const metric of metrics) {
    const { fieldName, format } = metric;
    for (const columnName of columns) {
      const column = dateColumns[columnName];
      const { name, fromFn, toFn, ...column_rest } = column;
      if (dateField) column_rest.fieldName = dateField;
      column_rest.values = {
        from: column_rest.dateType === 'relative' ? fromFn(reportDate) : column_rest.from,
        to: column_rest.dateType === 'relative' ? toFn(reportDate) : column_rest.to,
      };

      for (const modifier of modifiers) {
        const currentModifier = modifier.replace('[Comp Year]', compYear);
        const columnHeaders = [];
        if (metrics.length > 1) columnHeaders.push(fieldName);
        if (columns.length > 1) columnHeaders.push(columnName);
        if (modifiers.length > 1) columnHeaders.push(currentModifier);
        if (columnHeaders.length === 0) columnHeaders.push(fieldName); // if there's only 1 column, put the metric in the header
        const formatAdjusted = JSON.parse(JSON.stringify(format));
        if (currentModifier.slice(0, 4) === 'vs. ') {
          formatAdjusted.signDisplay = 'exceptZero';
          if (currentModifier.slice(-4) === ' (%)') {
            formatAdjusted.style = 'percent';
            formatAdjusted.maximumFractionDigits = 0;
          }
        }

        output.push({
          metric: fieldName,
          tableFilters: tableFilters_calculated,
          modifier: currentModifier,
          columnFilter: column_rest,
          columnHeaders,
          groupByDimensions: groupByDimensions_adjusted,
          format: formatAdjusted,
          showGrandTotal,
        });
      }
    }
  }

  return output;
};

export default buildTableDefinition;
