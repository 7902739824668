import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, sub } from 'date-fns';

const dateColumns = {
  'M-6': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 6 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 6 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'M-5': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 5 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 5 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'M-4': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 4 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 4 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'M-3': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 3 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 3 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'M-2': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 2 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 2 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'M-1': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfMonth(sub(new Date(date), { months: 1 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfMonth(sub(new Date(date), { months: 1 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  L3W: {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfWeek(sub(new Date(date), { weeks: 3 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfWeek(sub(new Date(date), { weeks: 1 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'W-3': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfWeek(sub(new Date(date), { weeks: 3 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfWeek(sub(new Date(date), { weeks: 3 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'W-2': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfWeek(sub(new Date(date), { weeks: 2 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfWeek(sub(new Date(date), { weeks: 2 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  'W-1': {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfWeek(sub(new Date(date), { weeks: 1 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => format(endOfWeek(sub(new Date(date), { weeks: 1 }), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  WTD: {
    type: 'date',
    dateType: 'relative',
    fromFn: (date) => format(startOfWeek(new Date(date), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    toFn: (date) => date,
  },
};

export default dateColumns;
