import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { sideBarItems } from 'utils/appDefinitions';
import TrafficConversionContainer from './TrafficConversion/TrafficConversionContainer';
import WeeklyReportsContainer from './WeeklyReports/WeeklyReportsContainer';

const IoContainer = () => {
  // Set page title
  React.useEffect(() => {
    document.title = `Orbital${process.env.REACT_APP_STAGE === 'production' ? '' : '-dev'} - ${sideBarItems.find((item) => item.name === 'Io').displayName}`;
  }, []);

  return (
    <div className="io">
      <Switch>
        <Route exact path="/Io/Traffic & Conversion" component={TrafficConversionContainer} />
        <Route exact path="/Io/Weekly Reports" component={WeeklyReportsContainer} />
      </Switch>
    </div>
  );
};

export default IoContainer;
