/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { extractQueryStringParams } from 'utils/util_old';
import { Intent, Toaster } from '@blueprintjs/core';
import localforage from 'localforage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Explorer from './Explorer';
import ganymedeActions from '../Actions/ganymedeActions';
import { MAX_ITEMS_GRID, MAX_ITEMS_LIST, MAX_ITEMS_LIST_COMPARE } from '../utils/constants';

const infoToaster = Toaster.create();
const copyDataToClipboardToaster = Toaster.create();

const ExplorerContainer = () => {
  const { screenshots, pagesIsUpdating, toggleGridView } = useSelector((store) => store.ganymedeReducer);
  const dispatch = useDispatch();

  const { search } = useLocation();
  let screenshotKeys = [];
  if (search) {
    const params = extractQueryStringParams(search);
    if (params.screenshotKeys) {
      // If URL contains screenshot keys
      screenshotKeys = JSON.parse(params.screenshotKeys);
    } else if (params.filtersSelection) {
      // If URL contains filterSelection in a query string, set filtersSelection and redirect to general URL
      // set filtersSelection for each param
      // dispatch(ganymedeActions.setFiltersFromLink(params.filtersSelection));
      // return <Redirect to="/Ganymede/Explorer" />;
    }
  }

  const [overlayImgData, setOverlayImgData] = React.useState(screenshotKeys);
  const [screenshotOverlayIsOpen, setScreenshotOverlayIsOpen] = React.useState(screenshotKeys.length > 0);
  const [selectedScreenshotKeys, setSelectedScreenshotKeys] = React.useState([]);
  const [orderedScreenshots, setOrderedScreenshots] = React.useState(screenshots);
  const [sorting, setSorting] = React.useState({ attribute: 'timeStamp', ascending: false, headerTitle: 'Date' });
  const [deletingLocalCopyKeys, setDeletingLocalCopyKeys] = React.useState([]);
  const [localNbOfScreenshots, setLocalNbOfScreenshots] = React.useState(0);

  const updateNbOfLocalScreenshotKeys = async (isSubscribed = true) => {
    const allKeys = await localforage.keys();
    const nb = allKeys.filter((key) => key.includes('ganymedeScreenshotData_'));
    if (isSubscribed) {
      setLocalNbOfScreenshots(nb.length);
    }
  };

  const handleSelectScreenshot = (key, selected) => {
    if (selected) {
      setSelectedScreenshotKeys([...selectedScreenshotKeys, key]);
    } else {
      setSelectedScreenshotKeys(selectedScreenshotKeys.filter((selectedKey) => selectedKey !== key));
    }
  };

  const handleSelectAllScreenshots = () => {
    // if all are selected, select none
    if (selectedScreenshotKeys.length === screenshots.length) {
      setSelectedScreenshotKeys([]);
    } else {
      // otherwise select all
      setSelectedScreenshotKeys(screenshots.map((screenshot) => screenshot.key));
    }
  };

  const displayScreenshot = (scrImgData) => {
    if (scrImgData) {
      setOverlayImgData([scrImgData]);
      setScreenshotOverlayIsOpen(true);
    } else {
      infoToaster.show({ message: 'Please download the screenshot first', intent: Intent.WARNING, icon: 'warning-sign' });
    }
  };

  const showOverlayScreenshots = async (screenshotObjects) => {
    // get screenshotObjects from localforage
    const compareImgsDataPromises = screenshotObjects.map((el) => localforage.getItem(`ganymedeScreenshotData_${el.key}`));
    const compareImgsData = await Promise.all(compareImgsDataPromises);
    const augmentedCompareImgsData = compareImgsData.map((el, i) => ({
      ...el,
      key: screenshotObjects[i].key,
      screenshot: screenshotObjects[i],
    }));
    setOverlayImgData(augmentedCompareImgsData.reverse());
    setScreenshotOverlayIsOpen(true);
  };

  const handleCompareScreenshots = async () => {
    // Ignore the screenshots that are selected but not currently displayed in the list
    const selected_visible_Screenshots = screenshots.filter((el) => selectedScreenshotKeys.includes(el.key));

    if (selected_visible_Screenshots.length > MAX_ITEMS_LIST_COMPARE) {
      infoToaster.show({ message: `You cannot compare more than ${MAX_ITEMS_LIST_COMPARE} screenshots`, intent: Intent.WARNING, icon: 'warning-sign' });
    } else {
      await showOverlayScreenshots(selected_visible_Screenshots);
    }
  };

  const history = useHistory();
  const handleCloseScreenshotOverlay = () => {
    history.push('/Ganymede/Explorer');
    setScreenshotOverlayIsOpen(false);
  };

  const sortScreenshots = (screenshotItems) => {
    const newOrderedScreenshots = [...screenshotItems].sort((a, b) => {
      if (sorting.ascending) {
        if (a[sorting.attribute] > b[sorting.attribute]) return 1;
        if (a[sorting.attribute] < b[sorting.attribute]) return -1;
        return 0;
      }
      if (a[sorting.attribute] < b[sorting.attribute]) return 1;
      if (a[sorting.attribute] > b[sorting.attribute]) return -1;
      return 0;
    });
    return newOrderedScreenshots;
  };

  // When screenshots change, show a warning if the number of screenshot exceeds the MAX
  React.useEffect(() => {
    const maxScreenshots = toggleGridView ? MAX_ITEMS_GRID : MAX_ITEMS_LIST;
    if (screenshots.length > maxScreenshots) {
      infoToaster.show({
        message: `${screenshots.length} returned. Only the first ${maxScreenshots} are displayed`,
        intent: Intent.WARNING,
        icon: 'warning-sign',
      });
    }
  }, [screenshots]);

  // When screenshots change, deselect the screenshots that are no longer visible, and order them
  React.useEffect(() => {
    setSelectedScreenshotKeys(screenshots.map((el) => el.key).filter((el) => selectedScreenshotKeys.includes(el)));
    const initiallyOrderedScreenshots = sortScreenshots(screenshots);
    setOrderedScreenshots(initiallyOrderedScreenshots);
  }, [screenshots]);

  // When sorting changes, order screenshots
  React.useEffect(() => {
    const currentOrderedScreenshots = orderedScreenshots;
    const newOrderedScreenshots = sortScreenshots(currentOrderedScreenshots);
    setOrderedScreenshots(newOrderedScreenshots);
  }, [sorting]);

  const handleSortItems = (headerTitle) => {
    const titleToAttribute = {
      Park: 'park',
      'Page Type': 'pageType',
      Date: 'timeStamp',
      Version: 'version',
    };
    // don't sort if user clicked on another column header (e.g. Time or Status)
    if (!Object.keys(titleToAttribute).includes(headerTitle)) return;
    const sortingAttribute = titleToAttribute[headerTitle];

    if (sortingAttribute === sorting.attribute) {
      // if already sorted with this attribute, reverse the order
      setSorting({ attribute: sortingAttribute, ascending: !sorting.ascending, headerTitle });
    } else if (sortingAttribute === 'timeStamp') {
      // if sorting by date, use descending order
      setSorting({ attribute: sortingAttribute, ascending: false, headerTitle });
    } else {
      // otherwise use ascending order
      setSorting({ attribute: sortingAttribute, ascending: true, headerTitle });
    }
  };

  const copyLinkToClipboard = async (e, keys) => {
    e.stopPropagation();

    const screenshostMetaData = keys.map((key) => {
      const screenshotMetaData = screenshots.find((el) => el.key === key);
      return {
        key: screenshotMetaData.key,
        screenshot: {
          park: screenshotMetaData.park,
          pageType: screenshotMetaData.pageType,
          version: screenshotMetaData.version,
          dateStr: screenshotMetaData.dateStr,
        },
      };
    });

    const baseURL = window.location.origin + window.location.pathname;
    const linkStr = `${baseURL}?screenshotKeys=${encodeURIComponent(JSON.stringify(screenshostMetaData))}`;
    try {
      await navigator.clipboard.writeText(linkStr);
      if (JSON.stringify(screenshostMetaData).length <= 2048) {
        copyDataToClipboardToaster.show({ message: 'Link copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
      } else {
        copyDataToClipboardToaster.show({
          message: 'Link copied to clipboard. Note that the link is over 2048 characters and may not function properly',
          intent: Intent.WARNING,
          icon: 'tick',
        });
      }
    } catch (err) {
      copyDataToClipboardToaster.show({ message: `Error copying link to clipboard: ${err.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
  };

  return (
    <Explorer
      isUpdating={pagesIsUpdating}
      screenshots={orderedScreenshots}
      handleSelectScreenshot={handleSelectScreenshot}
      handleSelectAllScreenshots={handleSelectAllScreenshots}
      selectedScreenshotKeys={selectedScreenshotKeys}
      handleSortItems={handleSortItems}
      sorting={sorting}
      displayScreenshot={displayScreenshot}
      overlayImgData={overlayImgData}
      setOverlayImgData={setOverlayImgData}
      screenshotOverlayIsOpen={screenshotOverlayIsOpen}
      showOverlayScreenshots={showOverlayScreenshots}
      handleCloseScreenshotOverlay={handleCloseScreenshotOverlay}
      handleCompareScreenshots={handleCompareScreenshots}
      localNbOfScreenshots={localNbOfScreenshots}
      deletingLocalCopyKeys={deletingLocalCopyKeys}
      setDeletingLocalCopyKeys={setDeletingLocalCopyKeys}
      updateNbOfLocalScreenshotKeys={updateNbOfLocalScreenshotKeys}
      copyLinkToClipboard={copyLinkToClipboard}
    />
  );
};

export default ExplorerContainer;
