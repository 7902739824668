import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { sideBarItems } from 'utils/appDefinitions';
import SalesAttendanceContainer from './SalesAttendance/SalesAttendanceContainer';

const EuropaContainer = (props) => {
  // Set page title
  React.useEffect(() => {
    document.title = `Orbital${process.env.REACT_APP_STAGE === 'production' ? '' : '-dev'} - ${
      sideBarItems.find((item) => item.name === 'Europa').displayName
    }`;
  }, []);

  return (
    <div className="europa">
      <Switch>
        <Route exact path="/Europa/Sales & Attendance" component={SalesAttendanceContainer} />
      </Switch>
    </div>
  );
};

export default EuropaContainer;
