import React from 'react';
// import PropTypes from 'prop-types';
import { Intent, Toaster } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import localforage from 'localforage';
import { useAuth0 } from 'Auth/react-auth0-spa';
import TrafficConversion from './TrafficConversion';
import ioActions from '../Actions/ioActions';
import { dimensions, filterDimensions_trafficConv } from '../utils/constants';

const ioEngineToaster = Toaster.create();

const TrafficConversionContainer = () => {
  const token = useSelector((store) => store.authReducer.token || '');
  const { user } = useAuth0();
  const fileDownLoadAccess = user[`https://orbital.hdhconsulting.be//app_metadata`]?.downloadFullData;
  const showGA_DLButton = fileDownLoadAccess?.GA_traffic_data || fileDownLoadAccess === 'all';
  const [downloadProgress, setDownloadProgress] = React.useState();
  const [dataInfo, setDataInfo] = React.useState({});
  const [filterValues, setFilterValues] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);

  const { options, filtersSelection } = useSelector((store) => store.ioReducer.trafficConversion);

  const ioEngineWorker = React.useMemo(() => new Worker(new URL('../utils/ioEngineWorker.js', import.meta.url)), []);

  // When dataEngineWorker changes, deal with it
  React.useEffect(() => {
    ioEngineWorker.onmessage = ({ data }) => {
      const { type, res } = data;
      switch (type) {
        case 'loadData':
          setDataInfo((preDataInfo) => ({
            ...preDataInfo,
            ...res,
          }));
          break;
        case 'updateFilterValues':
          setFilterValues(res.Io_trafficConversion_fullData);
          break;
        case 'getData':
          setFilteredData(res);
          break;
        default:
          break;
      }
    };
  }, [ioEngineWorker]);

  // When mounting, load data from localForage
  React.useEffect(() => {
    const asyncLoadLocalData = async () => {
      // console.time('Loading local data');
      const localData = (await localforage.getItem('Io_trafficConversion_fullData')) || [];
      // console.timeEnd('Loading local data');
      // console.log(localData[1]);
      if (localData.length > 0) {
        ioEngineWorker.postMessage({ table: 'Io_trafficConversion_fullData', type: 'loadData', payload: localData });
      } else {
        ioEngineToaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    asyncLoadLocalData();

    // When unmounting, kill the engineWorker
    return () => ioEngineWorker.terminate();
  }, []);

  // When data changes, update the filter values
  React.useEffect(() => {
    if (dataInfo.Io_trafficConversion_fullData?.nbRows > 0) {
      ioEngineWorker.postMessage({
        table: 'Io_trafficConversion_fullData',
        type: 'updateFilterValues',
        payload: { filterDimensions: filterDimensions_trafficConv },
      });
    }
  }, [dataInfo]);

  // Update filteredData when dataInfo, filterValues, filtersSelection, timeGrouping, priorYears or toggleCumulative changes
  React.useEffect(() => {
    if (dataInfo.Io_trafficConversion_fullData?.nbRows > 0 && Object.entries(filterValues).length > 0 && Object.entries(filtersSelection).length > 0) {
      const metrics = dataInfo.Io_trafficConversion_fullData.fields.filter((el) => !dimensions.includes(el));
      const filters = filtersSelection;

      ioEngineWorker.postMessage({
        table: 'Io_trafficConversion_fullData',
        type: 'getData',
        payload: {
          table: 'Io_trafficConversion_fullData',
          metrics,
          filters: { Park: filters.Park, Year: filters.Year, 'Year-Month': filters['Year-Month'], Channel: filters.Channel },
          options: { ...options.timeSeries, ...options.global },
        },
      });
    }
  }, [dataInfo, filterValues, filtersSelection, options]);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  const refreshData = async () => {
    // eslint-disable-next-line no-unused-vars
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted

    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    const { data, err } = await ioActions.downloadFullData(token, onDownloadProgress);
    // console.log({ emailReducedData });

    if (err) {
      ioEngineToaster.show({ message: `Error downloading the data file: ${err}`, intent: Intent.WARNING, icon: 'warning-sign' });
    } else {
      ioEngineWorker.postMessage({ table: 'Io_trafficConversion_fullData', type: 'loadData', payload: data });
      setDownloadProgress(null);
    }
    return () => {
      isSubscribed = false;
    };
  };

  return (
    <TrafficConversion
      refreshData={refreshData}
      filterValues={filterValues}
      chartData={filteredData}
      downloadProgress={downloadProgress}
      dataInfo={dataInfo}
      showGA_DLButton={showGA_DLButton}
    />
  );
};

export default TrafficConversionContainer;
