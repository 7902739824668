import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { sideBarItems } from 'utils/appDefinitions';
import ManageContainer from './Manage/ManageContainer';
import ExplorerContainer from './Explorer/ExplorerContainer';
import ganymedeActions from './Actions/ganymedeActions';
import PricesContainer from './Prices/PricesContainer';

const GanymedeContainer = () => {
  const { token } = useSelector((store) => store.authReducer);
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  // Set page title
  React.useEffect(() => {
    document.title = `Orbital${process.env.REACT_APP_STAGE === 'production' ? '' : '-dev'} - ${
      sideBarItems.find((item) => item.name === 'Ganymede').displayName
    }`;
  }, []);

  // When mounting: fetch the list of pages
  React.useEffect(() => {
    stableDispatch(ganymedeActions.getPageList(token));
  }, [stableDispatch, token]);

  return (
    <div className="ganymede">
      <Switch>
        <Route exact path="/Ganymede/Manage" component={ManageContainer} />
        <Route exact path="/Ganymede/Explorer" component={ExplorerContainer} />
        <Route path="/Ganymede/Explorer/:screenshotKeys" component={ExplorerContainer} />
        <Route exact path="/Ganymede/Prices" component={PricesContainer} />
      </Switch>
    </div>
  );
};

export default GanymedeContainer;
