// import { getColumnData } from 'Data/engine';

// const processTableData = (rawTableData) => {
//   const { metric } = rawTableData[0].request;
//   const isCalculatedMetric = typeof metric !== 'string' && metric.numerator && metric.denominator;

//   // console.log({ rawTableData });
//   const groupByDimensions = rawTableData[0].request.groupByDimensions.map((dim) => dim.fieldName);

//   // get list of unique dimension values
//   const allDims = rawTableData
//     .map((colData) => colData.data)
//     .flat(2)
//     .map((row) => Object.values(row).slice(0, groupByDimensions.length))
//     .map((row) => row.join('|||'));
//   const uniqueDims = [...new Set(allDims)].map((row) => row.split('|||'));
//   // console.log({ uniqueDims });

//   // Attach metrics to unique dimension values
//   const tableData_filled = rawTableData.map((colData) => {
//     const data = uniqueDims.map((uniqueDim) => {
//       const matchingRow = colData.data[0].find((row) => {
//         const rowDim = Object.values(row).slice(0, groupByDimensions.length);
//         return rowDim.join('|||') === uniqueDim.join('|||');
//       });
//       let output;
//       if (!isCalculatedMetric) {
//         const metricValue = matchingRow ? matchingRow[colData.request.metric] : 0;
//         let secondMetricValue = null;
//         if (colData.data.length === 2) {
//           const secondMatchingRow = colData.data[1].find((row) => {
//             const rowDim = Object.values(row).slice(0, groupByDimensions.length);
//             return rowDim.join('|||') === uniqueDim.join('|||');
//           });
//           secondMetricValue = secondMatchingRow ? secondMatchingRow[colData.request.metric] : 0;
//         }
//         output = [...uniqueDim, metricValue, secondMetricValue];
//       } else {
//         const numeratorValue = matchingRow ? matchingRow[colData.request.metric.numerator] : 0;
//         const denominatorValue = matchingRow ? matchingRow[colData.request.metric.denominator] : 0;
//         let secondNumeratorValue = null;
//         let secondDenominatorValue = null;
//         if (colData.data.length === 2) {
//           const secondMatchingRow = colData.data[1].find((row) => {
//             const rowDim = Object.values(row).slice(0, groupByDimensions.length);
//             return rowDim.join('|||') === uniqueDim.join('|||');
//           });
//           secondNumeratorValue = secondMatchingRow ? secondMatchingRow[colData.request.metric.numerator] : 0;
//           secondDenominatorValue = secondMatchingRow ? secondMatchingRow[colData.request.metric.denominator] : 0;
//         }
//         output = [...uniqueDim, numeratorValue, denominatorValue, secondNumeratorValue, secondDenominatorValue];
//       }
//       return output;
//     });
//     return data;
//   });
//   // console.log({ tableData_filled });

//   // Add Total row
//   const totalsRowMetrics = tableData_filled.map((colData) =>
//     colData.reduce((acc, row) => {
//       const metrics = row.slice(groupByDimensions.length);
//       return acc.map((val, i) => val + metrics[i]);
//     }, Array(colData[0].length - groupByDimensions.length).fill(0)),
//   );
//   const totalsRow = totalsRowMetrics.map((metrics) => [...Array(groupByDimensions.length).fill('Total'), ...metrics]);
//   const tableData_withTotals = tableData_filled.map((colData, i) => [...colData, totalsRow[i]]);
//   // console.log({ tableData_withTotals });

//   // calculate calculated metrics
//   const tableData_calculatedMetrics = tableData_withTotals.map((colData) => {
//     if (!isCalculatedMetric) return colData;
//     return colData.map((row) => {
//       const dims = row.slice(0, groupByDimensions.length);
//       const metrics = row.slice(groupByDimensions.length);
//       const [numeratorCY, denominatorCY, numeratorPY, denominatorPY] = metrics;
//       return [...dims, numeratorCY / denominatorCY, numeratorPY / denominatorPY];
//     });
//   });
//   // console.log({ tableData_calculatedMetrics });

//   // calculate vs. and vs. % values if needed
//   const tableData_calculatedDeltas = tableData_calculatedMetrics.map((colData, i) => {
//     const { modifier } = rawTableData[i].request;
//     const format = metric.format || '0,0';
//     return colData.map((row) => {
//       const dims = row.slice(0, groupByDimensions.length);
//       const [metricValue, secondMetricValue] = row.slice(groupByDimensions.length);
//       if (modifier.indexOf('vs.') > -1) {
//         if (modifier.indexOf('(%)') > -1) {
//           return [...dims, { value: metricValue / secondMetricValue - 1, format: '+0.0%' }];
//         }
//         return [...dims, { value: metricValue - secondMetricValue, format: metricValue > secondMetricValue ? `+${format}` : format }]; // trick needed for format because "+$0.00" doesn't seem to work for negative numbers
//       }
//       return [...dims, { value: metricValue, format }];
//     });
//   });
//   // console.log({ tableData_calculatedDeltas });
//   // console.table(tableData_calculatedDeltas);

//   // Transpose
//   const tableData_transposed = tableData_calculatedDeltas[0].map((el, i) => [
//     ...el.slice(0, groupByDimensions.length),
//     ...tableData_calculatedDeltas.map((colData) => colData[i][groupByDimensions.length]),
//   ]);
//   // console.log(tableData_transposed);

//   // add dimensions
//   // const tableDataWithDims = tableData_transposed.map((row) => {

//   // Apply sorts
//   // const { sortBy } = rawTableData[0].request;
//   // console.log(sortBy);

//   // LATER: apply limits and sorts

//   return tableData_transposed;
// };

// const getTableData = async (requests, tableName) => {
//   const columnDataPromises = requests.map((request) => getColumnData({ tableName, request }));
//   const tableData_raw = await Promise.all(columnDataPromises);
//   const tableData = processTableData(tableData_raw);
//   // console.log({ tableData });

//   return tableData;
// };

const getHeaders = (headers_raw, nbDimensions) => {
  // console.log({ headers_raw, nbDimensions });

  const headersTransposed = headers_raw[0].map((_, i) => headers_raw.map((header) => header[i]));
  const headers_withExtraColumns = headersTransposed.map((header) => [...Array(nbDimensions).fill(' '), ...header]);
  const headers_withColSpans = [];
  for (const header of headers_withExtraColumns) {
    const header_withColSpans = [];
    let previousHeader = '';
    let colSpan = 1;
    for (const cell of header) {
      if (cell === previousHeader) {
        colSpan += 1;
      } else {
        if (previousHeader) {
          header_withColSpans.push({ value: previousHeader, colSpan });
        }
        previousHeader = cell;
        colSpan = 1;
      }
    }
    header_withColSpans.push({ value: previousHeader, colSpan });
    headers_withColSpans.push(header_withColSpans);
  }
  const headers = headers_withColSpans;
  // console.log({ headers });

  // headerKeys
  const headerKeys = [];
  for (let i = 0; i < headers_withExtraColumns[0].length; i += 1) {
    headerKeys.push(headers_withExtraColumns.map((headerRow) => headerRow[i].value || `dim_${i + 1}`).join(', '));
  }
  // console.log({ headerKeys });

  return { headers, headerKeys };
};

const getHeaderKeys = (headers) => {
  const headerKeys = [];
  for (let i = 0; i < headers[0].length; i += 1) {
    headerKeys.push(headers.map((headerRow) => headerRow[i].value || `dim_${i + 1}`).join(', '));
  }
  console.log({ headerKeys });

  return headerKeys;
};

export { getHeaders, getHeaderKeys };
